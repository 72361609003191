import { stringFormat } from '@shared/lib/utils/stringFormat';
import { PageTypes } from '@shared/types';
import { getResultCertificateNumbers } from '../state/selectors/getResultCertificateNumbers';
import { DocumentResultErrorTypes, DocumentResultErrors } from '@shared/types/DocumentResultError';
import { ResultFieldProperties } from '@shared/types/ResultFieldProperties';
import { isSummaryRelevantFieldType } from '@shared/utils/isSummaryRelevantFieldType';
import { t } from 'i18next';
function checkPropertyExists(arr, type) {
    return arr.find(row => row.type === type);
}
function pushWarningOnce(errors, errorPayload) {
    const exists = errors.some(error => error.type === errorPayload.type && error.message === errorPayload.message);
    if (!exists) {
        errors.push(errorPayload);
    }
}
export function validateResults(results, id) {
    const errors = [];
    const icNumbers = Object.values(getResultCertificateNumbers(results));
    const tagExists = checkPropertyExists(results, PageTypes.tags);
    const drExists = checkPropertyExists(results, PageTypes.delivery);
    const processingTagsExists = checkPropertyExists(results, PageTypes.processing_tags);
    const processingSheetsExists = checkPropertyExists(results, PageTypes.processing_sheet);
    const validatedData = [];
    results.forEach((oldRow, rowIndex) => {
        let row = oldRow;
        if (isSummaryRelevantFieldType(row.type)) {
            if (row.certificateNumber === null || row.certificateNumber === '') {
                let message;
                if (row.type === PageTypes.tags) {
                    message = t(DocumentResultErrors.tagCouldNotMatchToCertificate);
                }
                if (row.type === PageTypes.delivery) {
                    message = t(DocumentResultErrors.deliveryRecordCouldNotMatchToCertificate);
                }
                if (row.type === PageTypes.processing_sheet) {
                    message = t(DocumentResultErrors.processingSheetCouldNotMatchToCertificate);
                }
                if (row.type === PageTypes.processing_tags) {
                    message = t(DocumentResultErrors.processingTagCouldNotMatchToCertificate);
                }
                errors.push(Object.assign({
                    index: rowIndex,
                    type: DocumentResultErrorTypes.error,
                    property: ResultFieldProperties.certificateNumber
                }, { message }));
                row = Object.assign(Object.assign({}, row), { hasError: true });
            }
            else if (!icNumbers.includes(row.certificateNumber)) {
                errors.push({
                    index: rowIndex,
                    type: DocumentResultErrorTypes.warning,
                    property: ResultFieldProperties.certificateNumber,
                    message: t(DocumentResultErrors.certificateNumberIsInvalid)
                });
                row = Object.assign(Object.assign({}, row), { hasError: true });
            }
        }
        else if (row.type === PageTypes.certificate) {
            if (row.calculatedQuantitiesTags !== undefined &&
                +row.quantities !== row.calculatedQuantitiesTags) {
                if (tagExists) {
                    errors.push({
                        index: rowIndex,
                        type: DocumentResultErrorTypes.error,
                        property: ResultFieldProperties.quantities,
                        message: stringFormat(t(DocumentResultErrors.quantitiesDoNotMatchWithCalculatedTags), [
                            `${row.quantities}`,
                            `${row.calculatedQuantitiesTags}`
                        ])
                    });
                    row = Object.assign(Object.assign({}, row), { hasError: true });
                }
                else {
                    pushWarningOnce(errors, {
                        type: DocumentResultErrorTypes.warning,
                        message: id
                            ? t(DocumentResultErrors.missingTagsDocument)
                            : t(DocumentResultErrors.missingTagsProject)
                    });
                }
            }
            if (row.calculatedQuantitiesDelivery !== undefined &&
                +row.quantities !== row.calculatedQuantitiesDelivery) {
                if (drExists) {
                    row = Object.assign(Object.assign({}, row), { hasError: true });
                    errors.push({
                        index: rowIndex,
                        type: DocumentResultErrorTypes.error,
                        property: ResultFieldProperties.quantities,
                        message: stringFormat(t(DocumentResultErrors.quantitiesDoNotMatchWithCalculatedDelivery), [`${row.quantities}`, `${row.calculatedQuantitiesDelivery}`])
                    });
                }
                else {
                    pushWarningOnce(errors, {
                        type: DocumentResultErrorTypes.warning,
                        message: id
                            ? t(DocumentResultErrors.missingDeliveryDocument)
                            : t(DocumentResultErrors.missingDeliveryProject)
                    });
                }
            }
            if (row.calculatedQuantitiesProcessingTags !== undefined &&
                +row.quantities !== row.calculatedQuantitiesProcessingTags) {
                if (processingTagsExists) {
                    row = Object.assign(Object.assign({}, row), { hasError: true });
                    errors.push({
                        index: rowIndex,
                        type: DocumentResultErrorTypes.error,
                        property: ResultFieldProperties.quantities,
                        message: stringFormat(t(DocumentResultErrors.quantitiesDoNotMatchWithCalculatedProcessingTags), [`${row.quantities}`, `${row.calculatedQuantitiesProcessingTags}`])
                    });
                }
                else {
                    pushWarningOnce(errors, {
                        type: DocumentResultErrorTypes.warning,
                        message: id
                            ? t(DocumentResultErrors.missingProcessingTagsDocument)
                            : t(DocumentResultErrors.missingProcessingTagsProject)
                    });
                }
            }
            if (row.calculatedQuantitiesProcessingSheets !== undefined &&
                +row.quantities !== row.calculatedQuantitiesProcessingSheets) {
                if (processingSheetsExists) {
                    row = Object.assign(Object.assign({}, row), { hasError: true });
                    errors.push({
                        index: rowIndex,
                        type: DocumentResultErrorTypes.error,
                        property: ResultFieldProperties.quantities,
                        message: stringFormat(t(DocumentResultErrors.quantitiesDoNotMatchWithCalculatedProcessingSheets), [`${row.quantities}`, `${row.calculatedQuantitiesProcessingSheets}`])
                    });
                }
                else {
                    pushWarningOnce(errors, {
                        type: DocumentResultErrorTypes.warning,
                        message: id
                            ? t(DocumentResultErrors.missingProcessingSheetsDocument)
                            : t(DocumentResultErrors.missingProcessingSheetsProject)
                    });
                }
            }
        }
        validatedData.push(row);
    });
    return { validatedData, errors };
}
