import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useState, useEffect } from 'react';
import { TransformWrapper } from 'react-zoom-pan-pinch';
import { Box } from '@mui/material';
import stylesDocument from '../document.module.scss';
import { Preview } from './Preview';
import { useDocumentStore } from '@entities/project/document';
export function DocumentPreview({ resizeCallbackRef }) {
    const { page, reset } = useDocumentStore();
    const imageWrapperRef = useRef(null);
    const [panPinchState, setPanPinchState] = useState({
        previousScale: 1,
        scale: 1,
        positionX: 0,
        positionY: 0
    });
    const handleMouseWheel = (ref) => {
        setPanPinchState(Object.assign({}, ref.state));
    };
    useEffect(() => {
        return () => {
            reset();
        };
    }, []);
    return (_jsx(Box, { className: stylesDocument.pageReview, children: page && (_jsx(Box, { className: stylesDocument.pageImageWrapper, ref: imageWrapperRef, children: _jsx(TransformWrapper, { initialScale: 1, onTransformed: handleMouseWheel, panning: { excluded: ['text', 'span'] }, children: ({ zoomIn, zoomOut, resetTransform }) => (_jsx(Preview, { panPinchState: panPinchState, imageWrapperRef: imageWrapperRef, zoomIn: zoomIn, zoomOut: zoomOut, resetTransform: resetTransform, resizeCallbackRef: resizeCallbackRef })) }) })) }));
}
