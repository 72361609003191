import { PageTypes, TableFieldProperty, TableFieldPropertyTypes } from '@shared/types';
export const inspectionCertificateTableFields = [
    {
        label: 'Material type',
        property: TableFieldProperty.material_type,
        type: TableFieldPropertyTypes.string
    },
    {
        label: 'Material name',
        property: TableFieldProperty.material_name,
        type: TableFieldPropertyTypes.string
    },
    {
        label: 'Material ID',
        property: TableFieldProperty.id,
        type: TableFieldPropertyTypes.string
    },
    {
        label: 'Diameter',
        property: TableFieldProperty.diameter,
        type: TableFieldPropertyTypes.string
    },
    {
        label: 'Length',
        property: TableFieldProperty.length,
        type: TableFieldPropertyTypes.float
    },
    {
        label: 'Quantity',
        property: TableFieldProperty.quantities,
        type: TableFieldPropertyTypes.integer
    },
    {
        label: 'Weight',
        property: TableFieldProperty.weight,
        type: TableFieldPropertyTypes.float
    }
];
export const tagTableFields = [
    {
        label: 'Material Id',
        property: TableFieldProperty.id,
        type: TableFieldPropertyTypes.string
    },
    {
        label: 'Diameter',
        property: TableFieldProperty.diameter,
        type: TableFieldPropertyTypes.string
    },
    {
        label: 'Length',
        property: TableFieldProperty.length,
        type: TableFieldPropertyTypes.float
    },
    {
        label: 'Quantity',
        property: TableFieldProperty.quantities,
        type: TableFieldPropertyTypes.integer
    }
];
export const drTableFields = tagTableFields;
export const processingSheetTableFields = [
    {
        label: 'Floor and Area',
        property: TableFieldProperty.location,
        type: TableFieldPropertyTypes.string
    },
    {
        label: 'Material type',
        property: TableFieldProperty.material_type,
        type: TableFieldPropertyTypes.string
    },
    {
        label: 'Material name',
        property: TableFieldProperty.material_name,
        type: TableFieldPropertyTypes.string
    },
    {
        label: 'Material Id',
        property: TableFieldProperty.id,
        type: TableFieldPropertyTypes.string
    },
    {
        label: 'Diameter',
        property: TableFieldProperty.diameter,
        type: TableFieldPropertyTypes.string
    },
    {
        label: 'Custom length',
        property: TableFieldProperty.custom_length,
        type: TableFieldPropertyTypes.float
    },
    {
        label: 'Custom quantity',
        property: TableFieldProperty.custom_quantities,
        type: TableFieldPropertyTypes.integer
    },
    {
        label: 'Total quantity',
        property: TableFieldProperty.total_quantities,
        type: TableFieldPropertyTypes.integer
    },
    {
        label: 'Length',
        property: TableFieldProperty.length,
        type: TableFieldPropertyTypes.float
    },
    {
        label: 'Quantity',
        property: TableFieldProperty.quantities,
        type: TableFieldPropertyTypes.integer
    }
];
export const processingTagsTableFields = processingSheetTableFields;
export const pageTypeToFields = {
    [PageTypes.certificate]: inspectionCertificateTableFields,
    [PageTypes.tags]: tagTableFields,
    [PageTypes.delivery]: drTableFields,
    [PageTypes.processing_sheet]: processingSheetTableFields,
    [PageTypes.processing_tags]: processingTagsTableFields,
    [PageTypes.undefined]: []
};
