import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en/translation.json';
import userEN from './en/user-translation.json';
import projectEN from './en/project-translation.json';
import translationJA from './ja/translation.json';
import userJA from './ja/user-translation.json';
import projectJA from './ja/project-translation.json';
import LanguageDetector from 'i18next-browser-languagedetector';
import { LanguagesEnum } from '@shared/types/Languages';
i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    fallbackLng: LanguagesEnum.EN,
    debug: process.env.NODE_ENV === 'development',
    resources: {
        en: {
            translation: translationEN,
            user: userEN,
            project: projectEN
        },
        ja: {
            translation: translationJA,
            user: userJA,
            project: projectJA
        }
    }
});
