import { PageTypes } from '@shared/types';
import { ResultFieldProperties } from '@shared/types/ResultFieldProperties';
export const resultValueNotAppliable = 'n/a';
export const getUnModifiedFields = (pageType) => {
    const commonFields = [ResultFieldProperties.companyName, ResultFieldProperties.shippingDate];
    switch (pageType) {
        case PageTypes.tags:
        case PageTypes.delivery:
            return [
                ...commonFields,
                ResultFieldProperties.location,
                ResultFieldProperties.materialType,
                ResultFieldProperties.materialName,
                ResultFieldProperties.customLength,
                ResultFieldProperties.customQuantities,
                ResultFieldProperties.totalQuantities,
                ResultFieldProperties.weight
            ];
        case PageTypes.processing_tags:
        case PageTypes.processing_sheet:
            return [...commonFields, ResultFieldProperties.weight];
        default:
            return commonFields;
    }
};
