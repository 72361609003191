export var DocumentResultErrorTypes;
(function (DocumentResultErrorTypes) {
    DocumentResultErrorTypes["error"] = "error";
    DocumentResultErrorTypes["warning"] = "warning";
})(DocumentResultErrorTypes || (DocumentResultErrorTypes = {}));
export var DocumentResultErrors;
(function (DocumentResultErrors) {
    DocumentResultErrors["tagCouldNotMatchToCertificate"] = "Tag could not be match to a certificate";
    DocumentResultErrors["deliveryRecordCouldNotMatchToCertificate"] = "Delivery record could not be match to a certificate";
    DocumentResultErrors["processingTagCouldNotMatchToCertificate"] = "Processing Tag could not be match to a certificate";
    DocumentResultErrors["processingSheetCouldNotMatchToCertificate"] = "Processing Sheet could not be match to a certificate";
    DocumentResultErrors["certificateNumberIsInvalid"] = "Certificate number is not part of this document";
    DocumentResultErrors["quantitiesDoNotMatchWithCalculatedTags"] = "The quantities (_) do not match the sum of quantities (_) from the tags";
    DocumentResultErrors["quantitiesDoNotMatchWithCalculatedDelivery"] = "The quantities (_) do not match the sum of quantities (_) from the delivery records";
    DocumentResultErrors["quantitiesDoNotMatchWithCalculatedProcessingTags"] = "The quantities (_) do not match the sum of quantities (_) from the processing tags";
    DocumentResultErrors["quantitiesDoNotMatchWithCalculatedProcessingSheets"] = "The quantities (_) do not match the sum of quantities (_) from the processing sheets";
    DocumentResultErrors["missingTagsDocument"] = "Tags are missing for current document";
    DocumentResultErrors["missingTagsProject"] = "Tags are missing for current project";
    DocumentResultErrors["missingDeliveryDocument"] = "Delivery records are missing for current document";
    DocumentResultErrors["missingDeliveryProject"] = "Delivery records are missing for current project";
    DocumentResultErrors["missingProcessingTagsDocument"] = "Processing Tags are missing for current document";
    DocumentResultErrors["missingProcessingTagsProject"] = "Processing Tags are missing for current project";
    DocumentResultErrors["missingProcessingSheetsDocument"] = "Processing sheets missing for current document";
    DocumentResultErrors["missingProcessingSheetsProject"] = "Processing sheets missing for current project";
})(DocumentResultErrors || (DocumentResultErrors = {}));
