import { resultFields } from '@entities/summary';
export function exportDocumentToCsv(data, filename, hasHeader = true) {
    const rowsCsv = data.reduce((acc, documentResultRow) => {
        const { certificateNumber, companyName, diameter, documentName, documentNumber, id, length, materialName, pageNumber, quantities, shippingDate, totalAmount, type, location, materialType, customLength, customQuantities, weight, totalQuantities } = documentResultRow;
        acc.push([
            sanitizeValue(pageNumber),
            sanitizeValue(documentName),
            sanitizeValue(type),
            sanitizeValue(location),
            sanitizeValue(certificateNumber),
            sanitizeValue(companyName),
            sanitizeValue(shippingDate),
            sanitizeValue(materialType),
            sanitizeValue(materialName),
            sanitizeValue(diameter),
            sanitizeValue(customLength),
            sanitizeValue(id),
            sanitizeValue(customQuantities),
            sanitizeValue(weight),
            sanitizeValue(totalQuantities),
            sanitizeValue(length),
            sanitizeValue(quantities)
        ].join('\t'));
        return acc;
    }, []);
    return hasHeader ? [...[getHeaders()], ...rowsCsv].join('\n') : rowsCsv.join('\n');
}
function getHeaders() {
    const resultFieldsObj = {};
    for (let i = 0; i < resultFields.length; i++) {
        //@ts-ignore
        resultFieldsObj[resultFields[i].property] = resultFields[i].label;
    }
    return [
        resultFieldsObj.pageNumber,
        resultFieldsObj.filename,
        resultFieldsObj.type,
        resultFieldsObj.location,
        resultFieldsObj.certificateNumber,
        resultFieldsObj.companyName,
        resultFieldsObj.shippingDate,
        resultFieldsObj.materialType,
        resultFieldsObj.materialName,
        resultFieldsObj.diameter,
        resultFieldsObj.customLength,
        resultFieldsObj.id,
        resultFieldsObj.customQuantities,
        resultFieldsObj.weight,
        resultFieldsObj.totalQuantities,
        resultFieldsObj.length,
        resultFieldsObj.quantities
    ].join('\t');
}
function sanitizeValue(val) {
    if (val === null) {
        return '';
    }
    if (typeof val === 'string') {
        return `"${val}"`;
    }
    return val;
}
