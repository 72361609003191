import { TableFieldPropertyTypes } from '@shared/types';
import { tagTableFields, drTableFields, inspectionCertificateTableFields, processingSheetTableFields, processingTagsTableFields } from '../constants/tableFields';
export function isNumericTableField(property) {
    return [
        ...tagTableFields,
        ...drTableFields,
        ...inspectionCertificateTableFields,
        ...processingSheetTableFields,
        ...processingTagsTableFields
    ]
        .filter(item => [TableFieldPropertyTypes.float, TableFieldPropertyTypes.integer].includes(item.type))
        .map(item => item.property)
        .includes(property);
}
