import { useMutation } from '@shared/lib/hooks';
import { useCallback } from 'react';
import { SummaryService } from '../service/Summary.service';
import { useSummaryStore } from '../state/useSummaryStore';
import { useTranslation } from 'react-i18next';
import { initDownloadByURL } from '@entities/summary/lib/utils/initDownloadByURL';
import { filterICs } from '../utils/filterICs';
import { ToastService } from '@shared/lib/services';
export function useUpdateSummary(onSuccess, projectId, projectName) {
    const { documentId, selectedRows, fetchSummary, setSelectedRows } = useSummaryStore();
    const { t } = useTranslation();
    const QUERY_OPTIONS = {
        queryKey: [`projects/${projectId}/enricher`],
        skipInvalidation: false
    };
    const filteredICsRows = filterICs(selectedRows);
    const updateEnricher = useCallback(({ isArchived, branch, toExport }) => {
        return SummaryService.updateProjectEnricher(projectId, projectName, isArchived, branch, filteredICsRows).catch(_err => {
            const errorMessage = t(`Error ${toExport ? 'exporting' : 'archiving'}, please try to ${toExport ? 'export' : 'archiv'} again`);
            ToastService.error(errorMessage);
            throw new Error(errorMessage);
        });
    }, [projectId, projectName, t, filteredICsRows]);
    const { mutate: update, isLoading: isUpdating, isSuccess: isUpdated } = useMutation({
        mutationFn: updateEnricher,
        onSuccess: (_, data, { toExport }) => {
            if (data.downloadUrl && toExport) {
                initDownloadByURL(data.downloadUrl);
            }
            onSuccess(data === null || data === void 0 ? void 0 : data.isArchived);
            setSelectedRows([]);
            fetchSummary(projectId, documentId || undefined);
        },
        options: QUERY_OPTIONS
    });
    return {
        update,
        isUpdating,
        isUpdated
    };
}
