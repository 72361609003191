import { useDocumentStore } from '@entities/project';
import { getPage } from '@entities/project/document/lib/state/useDocumentStore';
import { useMemo } from 'react';
import { getObjectHash } from '@shared/lib/utils/getObjectHash';
export function useHasUnsavedChanges() {
    const { initialPageHash } = useDocumentStore();
    const page = getPage();
    return useMemo(() => {
        if (!page || !initialPageHash) {
            return false;
        }
        const currentPageHash = getObjectHash(page);
        return currentPageHash !== initialPageHash;
    }, [page, initialPageHash]);
}
