import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styles from './ProjectHeader.module.scss';
import { EditProjectButton } from '@features/editProject/ui/EditProjectButton';
import { useCurrentProject } from '@entities/project';
import { ProjectTabs } from './ProjectTabs';
import { useState } from 'react';
import { TrimTextToolTip } from '@shared/ui';
import { Box } from '@mui/material';
import { ProjectNavigation } from '@pages/layout/ProjectHeader/ui/ProjectNavigation';
import { isUserAdmin } from '@shared/lib/hooks';
export function ProjectHeader() {
    const { project } = useCurrentProject();
    const [activeTab, setActiveTab] = useState();
    const hasAdminPrivilages = isUserAdmin();
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: styles.projectHeader, children: _jsxs(Box, { children: [_jsxs("div", { className: styles.titleEdit, children: [_jsx("div", { className: styles.projectHeaderTitle, children: _jsx(TrimTextToolTip, { title: project.name, maxMessageLength: 50, placement: "top" }) }), hasAdminPrivilages && _jsx(EditProjectButton, {})] }), _jsx(TrimTextToolTip, { title: project.location.name, maxMessageLength: 50, placement: "top", className: styles.projectHeaderLocation })] }) }), _jsxs("div", { className: styles.projectNav, children: [_jsx(ProjectTabs, { setActiveTab: setActiveTab }), _jsx(ProjectNavigation, { activeTab: activeTab })] })] }));
}
