import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSummaryStore } from '@entities/summary';
export function useArchivedSummary() {
    const { projectId } = useParams();
    const { data, fetchArchived, reset } = useSummaryStore();
    useEffect(() => {
        if (projectId) {
            fetchArchived(projectId);
        }
        return () => {
            reset();
        };
    }, [fetchArchived, projectId]);
    return {
        data
    };
}
