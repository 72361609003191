var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Checkbox, Radio, Dialog, FormControl, FormGroup, RadioGroup, FormLabel, FormControlLabel } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSummaryStore } from '@entities/summary';
import { ToastService } from '@shared/lib/services';
import { useSummaryCopy } from '../../../lib/hooks/useSummaryCopy';
import { useSummaryDownload } from '../../../lib/hooks/useSummaryDownload';
import { DocumentFormats } from '../../../lib/types/DocumentFormats';
import { documentTypes, useCurrentProject } from '@entities/project';
import { useTranslation } from 'react-i18next';
import styles from './DownloadModal.module.scss';
import { PageTypes } from '@shared/types';
export function DownloadModal({ open, onClose }) {
    const { download } = useSummaryDownload();
    const { copy } = useSummaryCopy();
    const { t } = useTranslation();
    const { data: resultData, document, documentId } = useSummaryStore();
    const { project } = useCurrentProject();
    const [documentFormat, setDocumentFormat] = useState(DocumentFormats.csv);
    const [documentTypesObj, setDocumentTypesObj] = useState({
        [PageTypes.certificate]: true,
        [PageTypes.tags]: true,
        [PageTypes.delivery]: true,
        [PageTypes.processing_tags]: true,
        [PageTypes.processing_sheet]: true
    });
    const [fileName, setFileName] = useState('');
    useEffect(() => {
        const isProjectLevel = !documentId;
        const fileName = isProjectLevel ? project.name : document.fileName;
        setFileName(fileName);
    }, [documentId, document, project]);
    const disableDownload = Object.values(documentTypesObj).every(item => !item);
    return (_jsxs(Dialog, { className: styles.downloadDialog, open: open, onClose: onClose, children: [_jsx(Box, { className: styles.modalHeader, children: _jsx(Box, { children: t('Download results') }) }), _jsxs(Box, { className: styles.modalContent, children: [_jsxs(FormControl, { className: styles.modalFormControl, children: [_jsx(FormLabel, { className: styles.modalFormLabel, id: "data-format-buttons-group-label", children: t('Data format') }), _jsxs(RadioGroup, { className: styles.modalRadioGroup, onChange: (event) => setDocumentFormat(event.target.value), "aria-labelledby": "data-format-radio-buttons-group-label", value: documentFormat, name: "data-format-radio-buttons-group", children: [_jsx(FormControlLabel, { className: styles.modalFormControlLabel, value: DocumentFormats.csv, control: _jsx(Radio, {}), label: DocumentFormats.csv }), _jsx(FormControlLabel, { className: styles.modalFormControlLabel, value: DocumentFormats.json, control: _jsx(Radio, {}), label: DocumentFormats.json })] })] }), _jsxs(FormControl, { className: styles.modalFormControl, children: [_jsx(FormLabel, { className: styles.modalFormLabel, id: "document-types-buttons-group-label", children: t('Document types') }), _jsx(FormGroup, { className: styles.modalFormGroup, children: Object.keys(Object.assign({}, PageTypes))
                                    // @ts-ignore
                                    .filter(key => key !== PageTypes.undefined)
                                    .map(key => {
                                    // @ts-ignore
                                    const docType = key;
                                    return (_jsx(_Fragment, { children: _jsx(FormControlLabel, { className: styles.modalFormControlLabel, control: _jsx(Checkbox, { checked: documentTypesObj[docType], name: docType, onChange: onDocumentTypesChange }), label: t(documentTypes[docType]) }) }));
                                }) })] })] }), _jsxs(Box, { className: styles.modalActions, children: [_jsx(Button, { variant: "text", size: "small", color: "secondary", onClick: onClose, children: t('Cancel') }), _jsx(Button, { variant: "outlined", disabled: disableDownload, size: "small", color: "primary", onClick: () => {
                            download(resultData, documentFormat, documentTypesObj, fileName);
                            onClose();
                        }, children: t('Download') }), _jsx(Button, { variant: "contained", disabled: disableDownload, size: "small", color: "primary", onClick: () => __awaiter(this, void 0, void 0, function* () {
                            const data = copy(resultData, documentFormat, documentTypesObj, fileName);
                            navigator.clipboard.writeText(data).then(() => {
                                ToastService.success(t('Result data was copied to clipboard'));
                            });
                            onClose();
                        }), children: t('Copy to clipboard') })] })] }));
    function onDocumentTypesChange(event) {
        setDocumentTypesObj(Object.assign(Object.assign({}, documentTypesObj), { [event.target.name]: event.target.checked }));
    }
}
