import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useBoolean } from '@conxai/react-kit';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from '../styles.module.scss';
import { ArchiveICDialog } from '../ArchiveICDialog';
import { useSummaryStore } from '@entities/summary';
export function ArchiveICButton() {
    const { t } = useTranslation();
    const [isArchiveICDialogOpen, , showArchiveICDialog, hideArchiveICDialog] = useBoolean(false);
    const { selectedRows } = useSummaryStore();
    return (_jsxs(_Fragment, { children: [_jsx(Button, { variant: "outlined", onClick: showArchiveICDialog, className: styles.button, disabled: !selectedRows.length, children: t('Archive') }), _jsx(ArchiveICDialog, { onClose: hideArchiveICDialog, visible: isArchiveICDialogOpen })] }));
}
