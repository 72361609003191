import { useEffect } from 'react';
import { calcTableRowCount, fields, isGeneralField, useDocumentStore, useSelectedFieldStore, getTableColumns } from '@entities/project/document';
import { useUpdateTable } from './useUpdateTable';
export function useReviewTableKeys() {
    const { addNewRow, updateCellValue, getTableRowData } = useUpdateTable();
    const { page } = useDocumentStore();
    const { field, selectField, index: fieldIndex } = useSelectedFieldStore();
    const currentTableColumns = getTableColumns(page);
    const tableRowCount = calcTableRowCount();
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (!field || isGeneralField(field)) {
                return;
            }
            switch (true) {
                case e.key === 'Tab' && e.shiftKey:
                    e.preventDefault();
                    selectPreviousField();
                    break;
                case e.key === 'Tab':
                    e.preventDefault();
                    selectNextField();
                    break;
                case e.key === 'ArrowDown':
                    e.preventDefault();
                    selectDownField(e.shiftKey);
                    break;
                case e.key === 'ArrowUp':
                    e.preventDefault();
                    selectUpField(e.shiftKey);
                    break;
                case e.key === 'Enter' && e.shiftKey:
                    e.preventDefault();
                    selectUpField();
                    break;
                case e.key === 'Enter':
                    e.preventDefault();
                    selectDownField();
                    break;
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
        // eslint-disable-next-line
    }, [field, fieldIndex, tableRowCount, page]);
    function selectUpField(withValue = false) {
        if (fieldIndex === undefined) {
            return;
        }
        const columnIndex = currentTableColumns.findIndex(v => v === field);
        if (fieldIndex === 0) {
            selectField(fields[fields.length - 1], undefined);
        }
        else {
            selectField(currentTableColumns[columnIndex], fieldIndex - 1);
            if (withValue) {
                // @ts-ignore
                const newValue = page.predictions.table[field.property].values[fieldIndex];
                updateCellValue(newValue, fieldIndex - 1);
            }
        }
    }
    function selectDownField(withValue = false) {
        const columnIndex = currentTableColumns.findIndex(v => v === field);
        if (fieldIndex !== undefined && fieldIndex < tableRowCount - 1) {
            selectField(currentTableColumns[columnIndex], fieldIndex + 1);
            // @ts-ignore
            const newValue = page.predictions.table[field.property].values[fieldIndex];
            if (page && field && !isGeneralField(field) && withValue) {
                updateCellValue(newValue, fieldIndex + 1);
            }
        }
        if (fieldIndex !== undefined && fieldIndex === tableRowCount - 1) {
            if (withValue) {
                // @ts-ignore
                const newValues = getTableRowData(fieldIndex);
                addNewRow(newValues);
            }
            else {
                addNewRow();
            }
            selectField(currentTableColumns[columnIndex], fieldIndex + 1);
        }
    }
    function selectNextField() {
        const columnIndex = currentTableColumns.findIndex(v => v === field);
        if (columnIndex < currentTableColumns.length - 1) {
            selectField(currentTableColumns[columnIndex + 1], fieldIndex);
        }
        else if (fieldIndex !== undefined && fieldIndex < tableRowCount - 1) {
            selectField(currentTableColumns[0], fieldIndex + 1);
        }
    }
    function selectPreviousField() {
        const columnIndex = currentTableColumns.findIndex(v => v === field);
        if (columnIndex > 0) {
            selectField(currentTableColumns[columnIndex - 1], fieldIndex);
        }
        else if (fieldIndex !== undefined && fieldIndex > 0) {
            selectField(currentTableColumns[currentTableColumns.length - 1], fieldIndex - 1);
        }
        else {
            selectField(fields[fields.length - 1], undefined);
        }
    }
}
