export var DENSITY;
(function (DENSITY) {
    DENSITY[DENSITY["D10"] = 0.56] = "D10";
    DENSITY[DENSITY["D13"] = 0.995] = "D13";
    DENSITY[DENSITY["D16"] = 1.56] = "D16";
    DENSITY[DENSITY["D19"] = 2.25] = "D19";
    DENSITY[DENSITY["D22"] = 3.04] = "D22";
    DENSITY[DENSITY["D25"] = 3.98] = "D25";
    DENSITY[DENSITY["D29"] = 5.04] = "D29";
    DENSITY[DENSITY["D32"] = 6.23] = "D32";
    DENSITY[DENSITY["D35"] = 7.51] = "D35";
    DENSITY[DENSITY["D38"] = 8.95] = "D38";
    DENSITY[DENSITY["D41"] = 10.5] = "D41";
    DENSITY[DENSITY["D51"] = 15.9] = "D51";
})(DENSITY || (DENSITY = {}));
