import { useTranslationUser } from '@shared/lib/hooks';
import { UserService } from '../services/User.service';
import { makeListQueryKey } from './utils/user';
import { useMutation } from '@shared/lib/hooks';
import { useCurrentProject } from '@entities/project';
import { useUsers } from './useUsers';
import { useAuth } from '@entities/auth';
export function useInviteUser() {
    const { project } = useCurrentProject();
    const { tuser } = useTranslationUser();
    const listQueryOptions = { queryKey: makeListQueryKey(project.id) };
    const { users: existingUsers } = useUsers();
    const { user } = useAuth();
    const { mutate: inviteUser, isLoading: isInviting, isSuccess: isInvited, isError, error } = useMutation({
        mutationFn: email => {
            if ([...existingUsers.map(existingUser => existingUser.email), user.email].includes(email)) {
                return Promise.reject(new Error(tuser('User already invited')));
            }
            return UserService.inviteUser(project.id, email).catch(err => {
                if (err.response.status === 409) {
                    throw new Error(tuser('User already invited'));
                }
                throw new Error(tuser('Error occurred. Please try to send new invitation.'));
            });
        },
        options: listQueryOptions
    });
    return {
        inviteUser,
        isInviting,
        isInvited,
        isError,
        error
    };
}
