var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { AuthService } from '@shared/lib/services';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TOKEN_REFRESH_PARAM } from '../constant/paramKey';
import { useQueryClient } from '@tanstack/react-query';
export function useTokenRefresh() {
    const [searchParams, setSearchParams] = useSearchParams();
    const needTokenRefresh = searchParams.get(TOKEN_REFRESH_PARAM) === 'true';
    const queryClient = useQueryClient();
    useEffect(() => {
        const refreshJWTToken = () => __awaiter(this, void 0, void 0, function* () {
            yield AuthService.currentSession(true);
            searchParams.delete(TOKEN_REFRESH_PARAM);
            queryClient.resetQueries();
            setSearchParams(searchParams);
        });
        if (needTokenRefresh) {
            refreshJWTToken();
        }
    }, [needTokenRefresh]);
    return { isTokenRefreshing: needTokenRefresh };
}
