import { FieldProperty } from '@shared/types';
export const fields = [
    {
        label: 'Company name',
        property: FieldProperty.company_name
    },
    {
        label: 'Shipping date',
        property: FieldProperty.shipping_date
    },
    {
        label: 'Certificate number',
        property: FieldProperty.certificate_number
    }
];
