import { dateFormatEn } from '@shared/lib/constants';
import { ResultFieldFormat } from '@shared/types/ResultField';
import { parse, startOfDay, isWithinInterval } from 'date-fns';
export function areResultValueMatch(value, filterValue, format) {
    if (format === ResultFieldFormat.dateTime) {
        const date = startOfDay(parse(value || '', dateFormatEn, new Date()));
        const [start, end] = filterValue;
        const startDate = start ? startOfDay(parse(start, 'yyyy-MM-dd', new Date())) : null;
        const endDate = end ? startOfDay(parse(end, 'yyyy-MM-dd', new Date())) : null;
        if (!(startDate || endDate)) {
            return true;
        }
        if (date)
            return isWithinInterval(date, {
                start: startDate || new Date(-8640000000000000), // Minimum date if startDate is null
                end: endDate || new Date(8640000000000000) // Maximum date if endDate is null
            });
    }
    if (typeof filterValue === 'string')
        return String(value).toLowerCase().includes(String(filterValue).toLowerCase());
    return (filterValue === null || filterValue === void 0 ? void 0 : filterValue.length)
        ? filterValue.some(filterItem => String(value).toLowerCase().includes(String(filterItem).toLowerCase()))
        : true;
}
