import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useHasUnsavedChanges } from '@widgets/document/lib/hooks/useHasUnsavedChanges';
import { Warning } from '@mui/icons-material';
import styles from './PageSaveStatus.module.scss';
import { useTranslation } from 'react-i18next';
export function PageSaveStatus() {
    const hasUnsavedChanges = useHasUnsavedChanges();
    const { t } = useTranslation();
    return hasUnsavedChanges ? (_jsxs("div", { className: styles.root, children: [_jsx(Warning, { color: `error` }), _jsx("span", { children: t(`Unsaved changes`) })] })) : null;
}
