var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
export function useMutation(_a) {
    var { mutationFn, onSuccess } = _a, _b = _a.options, { skipInvalidation } = _b, invalidateOptions = __rest(_b, ["skipInvalidation"]);
    const queryClient = useQueryClient();
    const [{ status, data, error }, setMutationStatus] = useState({});
    const mutate = (params) => {
        setMutationStatus({ status: 'pending' });
        mutationFn(params)
            .then(data => {
            setMutationStatus({ status: 'success', data });
            if (!skipInvalidation)
                queryClient.invalidateQueries(invalidateOptions);
            onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(queryClient, data, params);
        })
            .catch(err => {
            setMutationStatus({ status: 'error', error: err });
        });
    };
    useEffect(() => {
        if (error) {
            console.error(error.message);
        }
    }, [error]);
    return {
        data,
        mutate,
        isLoading: status === 'pending',
        isSuccess: status === 'success',
        isError: status === 'error',
        error
    };
}
