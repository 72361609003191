import { isNumericTableField } from './isNumericTableField';
export function preparePageDataOnSave(data) {
    Object.keys(data.table).forEach((key) => {
        const tableProperty = key;
        if (isNumericTableField(tableProperty)) {
            //@ts-ignore
            data.table[tableProperty].values = data.table[tableProperty].values.map(val => Number(val));
        }
        //@ts-ignore
        data.table[tableProperty].values = data.table[tableProperty].values.map(val => prepareField(val));
    });
    Object.keys(data).forEach(key => {
        const typedKey = key;
        // @ts-ignore
        if (isPageField(data[typedKey])) {
            // @ts-ignore
            data[typedKey].prediction = prepareField(data[typedKey].prediction);
        }
    });
    return data;
}
function prepareField(value) {
    if (value === null) {
        return null;
    }
    if (typeof value === 'string') {
        return value.trim().replace(/\s+/g, ' ');
    }
    return value;
}
function isPageField(data) {
    return (data.prediction !== undefined && data.coordinates !== undefined);
}
