import { TableFieldProperty } from '@shared/types';
import { DENSITY } from '@shared/types/Density';
export function calculateWeight(page) {
    const diameterValues = page.predictions.table[TableFieldProperty.diameter].values;
    const lengthValues = page.predictions.table[TableFieldProperty.length].values;
    const quantityValues = page.predictions.table[TableFieldProperty.quantities].values;
    const tableRowCount = diameterValues.length;
    return new Array(tableRowCount).fill(null).map((v, index) => {
        const diameter = diameterValues[index];
        return DENSITY[diameter] === undefined ||
            !lengthValues[index] ||
            !quantityValues[index] ||
            !diameterValues[index]
            ? null
            : +Math.round(Number(lengthValues[index]) * Number(DENSITY[diameter]) * Number(quantityValues[index]));
    });
}
