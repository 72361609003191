import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, DialogContent } from '@mui/material';
import { DialogContainer, DialogActions, DialogTitle } from '@shared/ui/Dialog';
import { useTranslation } from 'react-i18next';
import styles from '../styles.module.scss';
import { ToastService } from '@shared/lib/services';
import { useUpdateSummary } from '@entities/summary';
import { useCurrentProject } from '@entities/project';
import { CircularLoader } from '@shared/ui/Loader';
import { Typography } from '@shared/ui/Typography';
export function ArchiveICDialog({ onClose, visible }) {
    const { t } = useTranslation();
    const { project: { id, name } } = useCurrentProject();
    const onSuccess = () => {
        ToastService.success(t(`Files were successfully archived`));
        onClose();
    };
    const { update, isUpdating } = useUpdateSummary(onSuccess, id, name);
    return (_jsxs(DialogContainer, { classes: { paper: styles.container }, open: visible, children: [_jsx(DialogTitle, { variant: "h6", children: t('Archive ICs') }), _jsx(DialogContent, { children: t('Are you soure you want to archive selected ICs ?') }), _jsxs(DialogActions, { className: styles.actions, disableSpacing: true, children: [_jsx(Button, { variant: "text", onClick: onClose, disableElevation: true, className: styles.cancelButton, disabled: isUpdating, children: t('Cancel') }), _jsx("div", { className: styles.submitActions, children: isUpdating ? (_jsxs(Typography, { variant: "h4", classes: styles.processing, children: [t('Processing'), " ", _jsx(CircularLoader, { size: "0.8em", color: "inherit" }), ' '] })) : (_jsx(_Fragment, { children: _jsx(Button, { onClick: () => update({ isArchived: true, toExport: false, branch: 'tokyo' }), variant: "contained", className: styles.button, children: t('Archive') }) })) })] })] }));
}
