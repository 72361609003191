import { PageTypes } from '@shared/types';
import { pageTypeToFields } from '../constants/tableFields';
import { fields } from '../constants/fields';
export function validatePagePredictions(predictions, rowCount) {
    if (predictions.type === PageTypes.undefined || !predictions.table) {
        return true;
    }
    const tableFields = pageTypeToFields[predictions.type];
    const table = predictions.table;
    const isTableError = tableFields.some(field => {
        const tableField = table[field.property];
        if (!tableField) {
            return true;
        }
        if (!tableField.values || tableField.values.length < rowCount) {
            return true;
        }
        if (tableField.values.some(value => !value)) {
            return true;
        }
        return false;
    });
    if (predictions.type === PageTypes.certificate) {
        const isGeneralFieldError = fields.some(fieldItem => {
            const generalField = predictions[fieldItem.property] || undefined;
            if (!generalField)
                return true;
            if (!generalField.prediction)
                return true;
            return false;
        });
        return !(isTableError || isGeneralFieldError);
    }
    return !isTableError;
}
