//
// -------- types --------
//
export var TableFieldProperty;
(function (TableFieldProperty) {
    TableFieldProperty["diameter"] = "diameter";
    TableFieldProperty["length"] = "length";
    TableFieldProperty["id"] = "id";
    TableFieldProperty["weight"] = "weight";
    TableFieldProperty["quantities"] = "quantities";
    TableFieldProperty["material_name"] = "material_name";
    TableFieldProperty["material_type"] = "material_type";
    TableFieldProperty["custom_length"] = "custom_length";
    TableFieldProperty["custom_quantities"] = "custom_quantities";
    TableFieldProperty["total_quantities"] = "total_quantities";
    TableFieldProperty["location"] = "location";
})(TableFieldProperty || (TableFieldProperty = {}));
export var TableFieldPropertyTypes;
(function (TableFieldPropertyTypes) {
    TableFieldPropertyTypes["string"] = "string";
    TableFieldPropertyTypes["integer"] = "integer";
    TableFieldPropertyTypes["float"] = "float";
})(TableFieldPropertyTypes || (TableFieldPropertyTypes = {}));
export var PagePredictionProperties;
(function (PagePredictionProperties) {
    PagePredictionProperties["type"] = "type";
    PagePredictionProperties["certificate_number"] = "certificate_number";
    PagePredictionProperties["company_name"] = "company_name";
    PagePredictionProperties["document_number"] = "document_number";
    PagePredictionProperties["shipping_date"] = "shipping_date";
    PagePredictionProperties["total_amount"] = "total_amount";
    PagePredictionProperties["table"] = "table";
})(PagePredictionProperties || (PagePredictionProperties = {}));
export var FieldProperty;
(function (FieldProperty) {
    FieldProperty["company_name"] = "company_name";
    FieldProperty["shipping_date"] = "shipping_date";
    FieldProperty["certificate_number"] = "certificate_number";
})(FieldProperty || (FieldProperty = {}));
export * from './DocumentResultRow';
export * from './Page';
