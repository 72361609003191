import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DocumentPreparing, DocumentPreview, DocumentReview } from '@widgets/document';
import stylesDocument from '@widgets/document/ui/document.module.scss';
import { isDocumentFetched, useDocument } from '@entities/project/document';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { useRef } from 'react';
export function DocumentReviewPage() {
    useDocument();
    const isFetched = isDocumentFetched();
    const resizeCallbackRef = useRef(null);
    if (!isFetched) {
        return _jsx(DocumentPreparing, {});
    }
    const handleResize = () => {
        if (resizeCallbackRef.current) {
            resizeCallbackRef.current();
        }
    };
    return (_jsxs(PanelGroup, { direction: "horizontal", className: stylesDocument.reviewContainer, children: [_jsx(Panel, { defaultSize: 37, minSize: 37, children: _jsx(DocumentReview, {}) }), _jsx(PanelResizeHandle, { className: stylesDocument.resizeHandle }), _jsx(Panel, { minSize: 30, onResize: handleResize, children: _jsx(DocumentPreview, { resizeCallbackRef: resizeCallbackRef }) })] }));
}
