import { PageTypes } from '@shared/types';
export function filterResultDataByDocumentType(data, documentTypes) {
    return Object.values(documentTypes).every(item => item)
        ? data
        : data.filter((row) => {
            if (!documentTypes[PageTypes.certificate] && row.type === PageTypes.certificate) {
                return false;
            }
            if (!documentTypes[PageTypes.tags] && row.type === PageTypes.tags) {
                return false;
            }
            if (!documentTypes[PageTypes.delivery] && row.type === PageTypes.delivery) {
                return false;
            }
            if (!documentTypes[PageTypes.processing_sheet] && row.type === PageTypes.processing_sheet) {
                return false;
            }
            if (!documentTypes[PageTypes.processing_tags] && row.type === PageTypes.processing_tags) {
                return false;
            }
            return true;
        });
}
