import { isPageInspectionCertificate } from './isPageInspectionCertificate';
import { drTableFields, inspectionCertificateTableFields, processingSheetTableFields, processingTagsTableFields, tagTableFields } from '../constants/tableFields';
import { isPageTags } from './isPageTags';
import { isPageDeliveryRecord } from './isPageDeliveryRecord';
import { isPageProcessingSheet } from './isPageProcessingSheet';
import { isPageProcessingTags } from './isPageProcessingTags';
import { isPageUndefined } from './isPageUndefined';
export function getTableColumns(page) {
    switch (true) {
        case isPageInspectionCertificate(page):
            return inspectionCertificateTableFields;
        case isPageTags(page):
            return tagTableFields;
        case isPageDeliveryRecord(page):
            return drTableFields;
        case isPageProcessingSheet(page):
            return processingSheetTableFields;
        case isPageProcessingTags(page):
            return processingTagsTableFields;
        case isPageUndefined(page):
        default:
            return [];
    }
}
