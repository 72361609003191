export var FormatOptionsEnum;
(function (FormatOptionsEnum) {
    FormatOptionsEnum["tokyo"] = "tokyo";
    FormatOptionsEnum["choguku"] = "choguku";
    FormatOptionsEnum["other"] = "other";
})(FormatOptionsEnum || (FormatOptionsEnum = {}));
export const FormatOptions = {
    [FormatOptionsEnum.tokyo]: 'Tokyo',
    [FormatOptionsEnum.choguku]: 'Choguku',
    [FormatOptionsEnum.other]: 'Other'
};
