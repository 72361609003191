var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { PagePredictionProperties, PageTypes, TableFieldProperty } from '@shared/types';
import { DocumentService } from '../services/Document.service';
import { getObjectHash } from '@shared/lib/utils/getObjectHash';
const getInitialState = () => ({
    documentId: undefined,
    document: undefined,
    pageId: undefined,
    page: undefined,
    activePageIndex: 0,
    pageCount: 0,
    isErrorFetching: false,
    initialPageHash: undefined
});
export const useDocumentStore = create()(devtools((set, get) => (Object.assign(Object.assign({}, getInitialState()), { fetchDocumentWithPage: (projectId, documentId, pageId) => __awaiter(void 0, void 0, void 0, function* () {
        Promise.all([
            DocumentService.getDocument(projectId, documentId),
            DocumentService.getPage(projectId, documentId, pageId)
        ])
            .then(([document, page]) => {
            const activePageIndex = Number(pageId || 0);
            const modifiedPage = modifyPageResponse(page);
            set({
                documentId,
                document,
                pageId,
                page: modifiedPage,
                activePageIndex,
                pageCount: document.pageCount,
                initialPageHash: getObjectHash(modifiedPage)
            });
        })
            .catch(() => set({ isErrorFetching: true }));
    }), updatePage: (page) => {
        set({
            page
        });
    }, reset: () => {
        set(getInitialState());
    } }))));
export const getPage = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDocumentStore(state => state.page);
};
export const isDocumentFetched = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDocumentStore(state => state.documentId && state.document && state.pageId && state.page);
};
export const isDocumentFetching = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDocumentStore(state => !state.documentId || !state.document || !state.pageId || !state.page);
};
export const isErrorFetching = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDocumentStore(state => state.isErrorFetching);
};
export const calcTableRowCount = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDocumentStore(({ page }) => {
        var _a;
        return (_a = Object.values((page === null || page === void 0 ? void 0 : page.predictions).table || {})) === null || _a === void 0 ? void 0 : _a.map(({ values }) => values.length).reduce((s, c) => (s > c ? s : c), 0);
    });
};
function modifyPageResponse(page) {
    return Object.assign(Object.assign({}, page), { predictions: Object.assign(Object.assign({}, enforceRequiredPredictionFields(page.predictions)), { table: getTable(page) }) });
}
function enforceRequiredPredictionFields(predictions) {
    const requiredFields = [
        PagePredictionProperties.certificate_number,
        PagePredictionProperties.company_name,
        PagePredictionProperties.shipping_date
    ];
    requiredFields.forEach(field => {
        if (!predictions[field]) {
            if (field !== PagePredictionProperties.table && field !== PagePredictionProperties.type) {
                predictions[field] = {
                    prediction: null,
                    coordinates: [[]]
                };
            }
        }
    });
    return predictions;
}
function getTable(page) {
    var _a;
    if (page.predictions.type === PageTypes.undefined) {
        return page.predictions.table;
    }
    const table = {};
    if (page.predictions) {
        const tableLength = (_a = Object.values((page === null || page === void 0 ? void 0 : page.predictions.table) || {})) === null || _a === void 0 ? void 0 : _a.map(({ values }) => values.length).reduce((s, c) => (s > c ? s : c), 0);
        Object.entries(page.predictions.table).forEach(([key, value]) => {
            const values = 
            // @ts-ignore
            value.values.length > 0 ? value.values : Array.from({ length: tableLength }, () => null);
            table[key] = {
                coordinates: value.coordinates,
                values: values.map((v) => {
                    if (v) {
                        return v;
                    }
                    else {
                        return null;
                    }
                })
            };
        });
        // backward compatibility for table without some of the fields
        Object.values(TableFieldProperty).forEach(field => {
            if (!table[field]) {
                table[field] = {
                    // @ts-ignore
                    coordinates: Array.from({ length: tableLength }, () => []),
                    // @ts-ignore
                    values: Array.from({ length: tableLength }, () => null)
                };
            }
        });
    }
    return table;
}
