import { PagePredictionProperties, TableFieldProperty } from './index';
export var PageTypes;
(function (PageTypes) {
    PageTypes["certificate"] = "certificate";
    PageTypes["tags"] = "tags";
    PageTypes["delivery"] = "delivery";
    PageTypes["processing_sheet"] = "processing_sheet";
    PageTypes["processing_tags"] = "processing_tags";
    PageTypes["undefined"] = "undefined";
})(PageTypes || (PageTypes = {}));
