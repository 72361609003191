export var ResultFieldProperties;
(function (ResultFieldProperties) {
    ResultFieldProperties["index"] = "index";
    ResultFieldProperties["pageNumber"] = "pageNumber";
    ResultFieldProperties["filename"] = "filename";
    ResultFieldProperties["type"] = "type";
    ResultFieldProperties["location"] = "location";
    ResultFieldProperties["certificateNumber"] = "certificateNumber";
    ResultFieldProperties["companyName"] = "companyName";
    ResultFieldProperties["shippingDate"] = "shippingDate";
    ResultFieldProperties["materialType"] = "materialType";
    ResultFieldProperties["materialName"] = "materialName";
    ResultFieldProperties["diameter"] = "diameter";
    ResultFieldProperties["customLength"] = "customLength";
    ResultFieldProperties["id"] = "id";
    ResultFieldProperties["customQuantities"] = "customQuantities";
    ResultFieldProperties["weight"] = "weight";
    ResultFieldProperties["totalQuantities"] = "totalQuantities";
    ResultFieldProperties["length"] = "length";
    ResultFieldProperties["quantities"] = "quantities";
})(ResultFieldProperties || (ResultFieldProperties = {}));
