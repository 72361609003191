var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { ConfirmModal } from '@shared/ui';
import { DocumentStatuses } from '@shared/types/DocumentStatuses';
import { useDocumentProceed, calcTableRowCount, isDocumentFetching, validatePagePredictions, useDocumentStore } from '@entities/project/document';
import stylesDocument from '../document.module.scss';
import { useCurrentProject } from '@entities/project';
import { PagePredictionProperties, PageTypes } from '@shared/types';
import { useTranslationProject } from '@shared/lib/hooks';
export function ReviewSideNav() {
    const { documentId, document, activePageIndex, reset, pageCount, page } = useDocumentStore();
    const isValid = validatePagePredictions(page.predictions, calcTableRowCount());
    const TableRowCount = calcTableRowCount();
    const { proceedNext, proceedPrevious } = useDocumentProceed();
    const isFetching = isDocumentFetching();
    const navigate = useNavigate();
    const { project } = useCurrentProject();
    const { tproject } = useTranslationProject();
    const [proceedDirectionPrevious, setProceedDirectionPrevious] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const handleCloseConfirmModal = () => {
        setShowConfirmModal(false);
    };
    const handleNext = () => __awaiter(this, void 0, void 0, function* () {
        if (isValid) {
            yield proceedNext();
            setShowConfirmModal(false);
        }
        else {
            setShowConfirmModal(true);
        }
    });
    const handlePrevious = () => __awaiter(this, void 0, void 0, function* () {
        setProceedDirectionPrevious(true);
        if (isValid) {
            yield proceedPrevious();
            setShowConfirmModal(false);
        }
        else {
            setShowConfirmModal(true);
        }
    });
    const isPageNotRelevant = page.predictions[PagePredictionProperties['type']] === PageTypes['undefined'];
    const isNextButtonDisabled = isFetching || (!isPageNotRelevant ? TableRowCount === 0 : false);
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { className: stylesDocument.prevNext, children: [_jsx(Button, { variant: "outlined", disabled: isFetching || activePageIndex === 0 || (document === null || document === void 0 ? void 0 : document.status) === DocumentStatuses.validated, onClick: handlePrevious, children: tproject('Previous page') }), _jsx(Button, { disabled: isNextButtonDisabled, variant: "contained", onClick: handleNext, children: activePageIndex === pageCount - 1 || (document === null || document === void 0 ? void 0 : document.status) === 'validated'
                            ? tproject('Finalize')
                            : tproject('Next page') })] }), _jsx(ConfirmModal, { open: showConfirmModal, title: tproject('Are you sure you want to proceed with missing field?'), description: tproject('There are some missing fields as predictions. Do you want to continue with the missing fields in predictions?'), okButtonLabel: tproject('Proceed'), onClose: handleCloseConfirmModal, onProceed: () => {
                    proceedDirectionPrevious ? proceedPrevious() : proceedNext();
                    handleCloseConfirmModal();
                } })] }));
}
