import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styles from './ProjectsPage.module.scss';
import { AddProjectButton } from '@features/createProject/ui/AddProjectButton';
import { ProjectList } from '@widgets/projectList';
import { useTranslation } from 'react-i18next';
import { isUserAdmin } from '@shared/lib/hooks';
export function ProjectsPage() {
    const { t } = useTranslation();
    const hasAdminPrivilages = isUserAdmin();
    return (_jsxs(_Fragment, { children: [_jsxs("div", { className: styles.projectsHeader, children: [_jsx("div", { className: styles.projectTitle, children: t('Projects') }), hasAdminPrivilages && _jsx(AddProjectButton, {})] }), _jsx(ProjectList, {})] }));
}
