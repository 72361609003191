import { jsx as _jsx } from "react/jsx-runtime";
import { useAuth } from '@entities/auth';
import { LoadingPagePlaceholder } from '@shared/ui/LoadingPagePlaceholder';
import { useTokenRefresh } from '../../lib/hooks/useTokenRefresh';
export function ProtectedLayoutWrapper({ children }) {
    const { user } = useAuth();
    const { isTokenRefreshing } = useTokenRefresh();
    if (!user || isTokenRefreshing) {
        return _jsx(LoadingPagePlaceholder, {});
    }
    return children;
}
