import { ResultFieldFormat } from '@shared/types/ResultField';
import { ResultFieldProperties } from '@shared/types/ResultFieldProperties';
export const resultFields = [
    {
        label: 'Page Number',
        property: ResultFieldProperties.pageNumber,
        columnWidth: 100
    },
    {
        label: 'File name',
        property: ResultFieldProperties.filename,
        format: ResultFieldFormat.input,
        columnWidth: 190
    },
    {
        label: 'Document type',
        property: ResultFieldProperties.type,
        columnWidth: 150
    },
    {
        label: 'Floor and Area',
        property: ResultFieldProperties.location,
        columnWidth: 150
    },
    {
        label: 'Certificate number',
        property: ResultFieldProperties.certificateNumber,
        columnWidth: 150
    },
    {
        label: 'Company name',
        property: ResultFieldProperties.companyName,
        filter: true,
        format: ResultFieldFormat.select,
        columnWidth: 190
    },
    {
        label: 'Shipping date',
        property: ResultFieldProperties.shippingDate,
        filter: true,
        format: ResultFieldFormat.dateTime,
        columnWidth: 100
    },
    {
        label: 'Material type',
        property: ResultFieldProperties.materialType,
        columnWidth: 100
    },
    {
        label: 'Material name',
        property: ResultFieldProperties.materialName,
        filter: true,
        format: ResultFieldFormat.select,
        columnWidth: 150
    },
    {
        label: 'Diameter',
        property: ResultFieldProperties.diameter,
        columnWidth: 100
    },
    {
        label: 'Custom length',
        property: ResultFieldProperties.customLength,
        columnWidth: 100
    },
    {
        label: 'Material ID',
        property: ResultFieldProperties.id,
        columnWidth: 100
    },
    {
        label: 'Custom quantity',
        property: ResultFieldProperties.customQuantities,
        columnWidth: 100
    },
    {
        label: 'Weight',
        property: ResultFieldProperties.weight,
        columnWidth: 100
    },
    {
        label: 'Total quantity',
        property: ResultFieldProperties.totalQuantities,
        columnWidth: 100
    },
    {
        label: 'Length',
        property: ResultFieldProperties.length,
        columnWidth: 100
    },
    {
        label: 'Quantity',
        property: ResultFieldProperties.quantities,
        columnWidth: 100
    }
];
