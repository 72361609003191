import { useEffect } from 'react';
import { fields } from '../constants/fields';
import { useSelectedFieldStore } from '../state/useSelectedFieldStore';
import { isGeneralField } from '../utils/isGeneralField';
import { useDocumentStore } from '../state/useDocumentStore';
import { getTableColumns } from '../utils/getTableColumns';
export function useReviewGeneralFieldsKeys() {
    const { field, selectField } = useSelectedFieldStore();
    const { page } = useDocumentStore();
    const tableFields = getTableColumns(page);
    useEffect(() => {
        const handleKeyDown = (e) => {
            if (!field || !isGeneralField(field)) {
                return;
            }
            switch (true) {
                case (e.key === 'Tab' || e.key === 'Enter') && e.shiftKey:
                    e.preventDefault();
                    selectPreviousField();
                    break;
                case e.key === 'Tab' || e.key === 'Enter':
                    e.preventDefault();
                    selectNextField();
                    break;
                case e.key === 'ArrowDown':
                    e.preventDefault();
                    selectNextField();
                    break;
                case e.key === 'ArrowUp':
                    e.preventDefault();
                    selectPreviousField();
                    break;
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [field]);
    function selectNextField() {
        const index = fields.findIndex(v => v === field);
        if (index < fields.length - 1) {
            selectField(fields[index + 1]);
        }
        else {
            selectField(tableFields[0], 0);
        }
    }
    function selectPreviousField() {
        const index = fields.findIndex(v => v === field);
        if (index > 0) {
            selectField(fields[index - 1]);
        }
    }
}
