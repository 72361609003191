import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { MdCheck } from 'react-icons/md';
import { ErrorIcon } from '@shared/ui/icons';
import { Datepicker } from '@shared/ui';
import { FieldProperty, PageTypes } from '@shared/types';
import { fields, useReviewGeneralFieldsKeys, useDocumentStore, useSelectedFieldStore, isGeneralField } from '@entities/project/document';
import styles from './DocumentFields.module.scss';
import classNames from 'classnames';
import { onInputPasteText } from '@entities/project/document';
import { useTranslation } from 'react-i18next';
import { useGetDateFormat, useTranslationProject } from '@shared/lib/hooks';
import { format } from 'date-fns';
export function GeneralDataFields({ selectedRef }) {
    useReviewGeneralFieldsKeys();
    const { field, selectField } = useSelectedFieldStore();
    const { updatePage, page } = useDocumentStore();
    const [showEditModal, setShowEditModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const dateFormat = useGetDateFormat();
    const { t } = useTranslation();
    const { tproject } = useTranslationProject();
    useEffect(() => {
        if (page && PageTypes.certificate === page.predictions.type) {
            selectField(fields[0]);
        }
    }, []);
    useEffect(() => {
        if (field) {
            if (field.property === FieldProperty.shipping_date) {
                setShowEditModal(true);
                setAnchorEl(selectedRef.current);
            }
            else if (showEditModal) {
                setShowEditModal(false);
                setAnchorEl(null);
            }
        }
        // eslint-disable-next-line
    }, [field, selectedRef]);
    const handleCloseEditModal = () => {
        setShowEditModal(false);
    };
    const getFieldValue = (field, returnFormatValue = true) => {
        var _a;
        if (!page) {
            return '';
        }
        const value = (_a = page.predictions[field.property]) === null || _a === void 0 ? void 0 : _a.prediction;
        if (!returnFormatValue) {
            return value;
        }
        if (field.property === FieldProperty.shipping_date && value !== null && value !== '') {
            return format(new Date(value + 'T00:00:00.000'), dateFormat);
        }
        else {
            return value;
        }
    };
    const handleItemClick = (e, field) => {
        setShowEditModal(false);
        selectField(field);
        if (field.property === FieldProperty.shipping_date) {
            setShowEditModal(true);
            setAnchorEl(e.currentTarget);
        }
        else {
            if (document.activeElement !== e.currentTarget.lastChild) {
                e.currentTarget.lastChild.select();
            }
        }
    };
    const handleFieldChange = (value) => {
        if (field && isGeneralField(field) && page) {
            updatePage(Object.assign(Object.assign({}, page), { predictions: Object.assign(Object.assign({}, page.predictions), { [field.property]: {
                        coordinates: page.predictions[field.property]
                            ? page.predictions[field.property].coordinates
                            : [[]],
                        prediction: value
                    } }) }));
        }
    };
    return (_jsx(_Fragment, { children: _jsx(Box, { className: styles.generalDataFieldsBox, children: fields.map((item, index) => {
                const value = getFieldValue(item);
                return (_jsxs(Box, { position: 'relative', children: [_jsxs(Box, { className: styles.formElement, ref: item === field ? selectedRef : null, onClick: e => handleItemClick(e, item), children: [_jsxs(Box, { display: 'flex', alignItems: 'center', gap: 8, className: styles.generalFieldLabelContainer, children: [(value === null || value === void 0 ? void 0 : value.toString().length) > 0 ? _jsx(MdCheck, { size: 18 }) : _jsx(ErrorIcon, {}), _jsx(Box, { className: classNames(styles.documentLabel, {
                                                [styles.error]: (value === null || value === void 0 ? void 0 : value.toString().length) > 0
                                            }), children: t(item.label) })] }), item.property !== FieldProperty.shipping_date ? (_jsx("input", { className: styles.fieldInput, type: "text", onPaste: e => {
                                        e.preventDefault();
                                        handleFieldChange(onInputPasteText(e));
                                    }, value: value, placeholder: tproject('Enter manually'), onChange: e => handleFieldChange(e.target.value) })) : (_jsx("input", { className: styles.fieldInput, type: "text", value: value, readOnly: true }))] }), field &&
                            item === field &&
                            field.property === FieldProperty.shipping_date &&
                            showEditModal && (_jsx(Datepicker, { show: item === field && showEditModal, anchorEl: anchorEl, data: getFieldValue(item, false), onClose: handleCloseEditModal, onConfirm: e => handleFieldChange(e.target.value) }))] }, index));
            }) }) }));
}
