import { TableFieldProperty } from '@shared/types';
export const inspectionCertificateTableColumnsWidth = {
    total: 46,
    actions: 2,
    [TableFieldProperty.material_type]: 8,
    [TableFieldProperty.material_name]: 6,
    [TableFieldProperty.id]: 5,
    [TableFieldProperty.diameter]: 5,
    [TableFieldProperty.length]: 8,
    [TableFieldProperty.quantities]: 4,
    [TableFieldProperty.weight]: 8
};
export const tagTableColumnsWidth = {
    total: 9,
    actions: 1,
    [TableFieldProperty.diameter]: 2,
    [TableFieldProperty.length]: 2,
    [TableFieldProperty.id]: 2,
    [TableFieldProperty.quantities]: 2
};
export const drTableColumnsWidth = tagTableColumnsWidth;
export const processingSheetTableColumnsWidth = {
    total: 49,
    actions: 2,
    [TableFieldProperty.location]: 4,
    [TableFieldProperty.material_type]: 8,
    [TableFieldProperty.material_name]: 4,
    [TableFieldProperty.id]: 3,
    [TableFieldProperty.diameter]: 4,
    [TableFieldProperty.length]: 6,
    [TableFieldProperty.quantities]: 4,
    [TableFieldProperty.custom_length]: 6,
    [TableFieldProperty.custom_quantities]: 4,
    [TableFieldProperty.total_quantities]: 4
};
export const processingTagsTableColumnsWidth = processingSheetTableColumnsWidth;
